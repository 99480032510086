@import '../../../../styles/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: $info;
}

.child1, .child2 {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    width: 90%;
    border-radius: 20px;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: #FFF;
}

.cardContainer {
    display: flex;
    width:100%;
    overflow-x: auto;
    align-items: center;
    scrollbar-width: none;
    gap: 10px;
    scroll-snap-type: x mandatory;
}

.cardContainer > * {
    scroll-snap-align: center; /* Snap hvert barn til sentrum */
}

.cardContainer::-webkit-scrollbar {
    display:none;
}

@media (min-width: 1000px) {

    .cardContainer {
        flex-wrap: wrap;
    }

    .container{
        flex-direction: row;
        align-items:start;
        justify-content: space-evenly;
        padding-top: 0;
        overflow-y: hidden;
        height:90vh;
        margin-top: auto;
        padding-bottom: 0;
        padding-top: 0;
    }

    .col1 {
        width: 55%;
        height: 100%;   
        overflow-y: auto;
        padding-bottom: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .col2 {
        width: 35%;
        height: 100%;
        overflow-y: auto;
        padding-bottom: 2%;
    }

    .child1 {
        width: 95%;
        margin: auto;
        padding-top: 2%;
        margin-top: 2%;
    }

    .overflowY {
        overflow-y: auto;
        height:100%;
    }

    .child2 {
        width: 95%;
        margin: auto;
    }

    .child3 {
        width: 95%;
        margin:auto;
    }
}