@import "./variables.scss";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

$font-family-base: "yaldevi", sans-serif;
$font-weight: 400;
$font-style: normal;

// .body {
//     overflow-y: auto;
// }

.body::-webkit-scrollbar {
    display: none;
}

/* Skjul scrollbar i Firefox */
.body {
    scrollbar-width: none; /* Skjuler scrollbar i Firefox */
    background-color: $info;
}

.button {
    border-radius: 5px;
}

.custom-shadow {
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
    100% {
        opacity:1;
        transform: translateY(0);
    }
}

.appearing-container {
    opacity:0;
    animation: fadeInUp 0.5s ease forwards;
}

// @media (min-width: 1000px) {
//     body {
//         overflow-y: hidden;
//     }
// }

.chevron {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-right: 2px solid gray;
    border-bottom: 2px solid gray;
    transform: rotate(-45deg);
  }

  .height {
    min-height:80vh;
  }