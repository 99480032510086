@import '../../../styles/variables.scss';

.cardContainer {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    /* background-position: center;
    background-size: cover;
    background-image: url(../../../images/klienter.webp);
    background-color: rgba(255, 255, 255, 0.4);
    background-blend-mode: overlay; */
    padding-left: 2%;
    padding-right: 2%;
    scrollbar-width: none; /* Fjerner scrollbar i Firefox */
    -ms-overflow-style: none;
    align-items: center;
    gap:20px;
    padding-bottom: 10px;
}
.cardContainer::-webkit-scrollbar {
    display: none;
}

.loadMoreButton {
    margin-top: 4%;
    border-radius: 5px;
    border: none;
    background-color: lightgray;
    padding: 15px 30px;
    color: #000;

    &:hover {
        cursor: pointer;
    }

    @media(min-width: 768px) {
        transition: scale 0.3s ease;
        &:hover {
            scale: 1.05;
        }
    }
}

.sub1, .sub2, .sub3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
    scrollbar-width: none; /* Fjerner scrollbar i Firefox */
    -ms-overflow-style: none;
    //background-color: #FFF;
}

.sub1, .sub2, .sub3 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    //box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    padding: 10px;
}

.sub2 {
    margin-bottom: 70px;
}

/* .sub2, .sub3 {
    padding-left: 2%;
    box-shadow: 0px 0px 25px rgba(0,0,0,0.2);
    border-radius: 20px;
} */

.sub3{
    margin-top: 2%;
}

.card {
    min-width: 55vw;
    max-width: 55svw;
    height: auto;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    margin-top: 1.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: $info;
    justify-content: space-evenly;
    margin-right: auto;
    margin-left: auto;
    overflow-x: hidden;
    padding-top: 3%;
    border: 2px solid transparent;
    transition: 0.3s ease border-color;
    //background-color: rgba(255, 255, 255, 0.95);
    background-color: #FFF;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    background-color: $info;
    //padding-top: 30px;
}
.container::-webkit-scrollbar {
    display: none;
}

/* Skjul scrollbar i Firefox */
.container {
    scrollbar-width: none; /* Skjuler scrollbar i Firefox */
}

.search{
    width: 50%;
    border:none;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    padding: 5px 15px;
    margin-bottom: 10px;
}

.card:hover {
    border-radius: 0px 20px 0px 20px;
    cursor: pointer;
    border-color: #FFB34B;
}

.profilePictureContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    margin-top: 5%;
}

.clientProfilePictureContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    margin-top: 5%;
}

.profilePicture {
    object-fit: cover;
    width: 30%;
    height:30%;
    border-radius: 50%;
    background-color: #FFF;
}

.clientProfilePicture {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}

.name{
    font-size: 1.2em;
    text-align: center;
    font-weight: 700;
    margin-top: 3%;
    width: 100%;
    margin: auto;
    overflow-x: hidden;
    overflow-y: hidden;
}

.infoDiv {
    height:40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2%;
}

.info {
    width:95%;
    word-wrap: break-word;
    font-size: 0.9em;
    margin:auto;
    text-align: center;
    overflow-x: hidden;
    font-style: italic;
    font-weight: 600;
}

.modalImageContainer {
    width:100%;
}

.modal {
    margin-top: 15%;
    margin-bottom: 10%;
    padding-bottom: 25%;
}

.closeButton{
    padding-left: 5%;
    padding-right: 5%;
    background-color: lightcoral;
    border-radius: 10px;
}

.closeButton:hover {
    background-color: #000;
    cursor: pointer;
    color:lightcoral;
}

.subChild1, .subChild2 {
    margin-top: 5%;
}

.checkInDiv, .subChild2{
    width:98%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    /* background-image: url(../../../images/weigh-in.jpg);
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.25);
    background-blend-mode: overlay;
    background-position: center 60%; */
}

.checkInHeader {
    display:flex;
    flex-direction: column;
    width:100%;
    align-items: center;
    padding-left: 1%;
    padding-right: 1%;
}

.searchBar {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
}

.searchInput {
    padding: 0px 15px;
    border-radius: 20px;
    border: none;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
    line-height: 2;
    vertical-align: bottom;
    box-sizing: border-box;
}

.checkInOverskrift {
    font-size: 1.7em;
    width: 100%;
    color: #000;
    padding-left: 1%;
    padding-top: 3%;
}
.checkInKnapp{
    width:30%;
}

.checkInCardsContainer,  .workoutCardsContainer{
    display: flex;
    flex-direction: column;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width:100% !important;
}

.subChild2 {
    margin-bottom: 10%;
}

.nutritionContainer {
    width: 95%;
    margin-bottom: 5%;
}

/* @media (min-width: 720px) {
    
    .imageContainer{
        width: 10vh;
        height: 10vh;
    }

    .cardContainer {
        flex-wrap: wrap;
    }

    .card {
        min-width: 25%;
        max-width: 25%;
        height: 40vh;
        max-height:400px;
        padding: 2%;
        margin-right: 0;
    }

    .info {
        font-size: 1em;
    }

    .container2 {
        display: flex;
    }

    .communicationsContainer {
        width: 30%;
    }
} */

@media(min-width: 1000px) {

    .container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }
    .cardContainer{
        border-radius: 20px;
        /* box-shadow: 2px 2px 10px rgba(0,0,0,0.2); */
        scrollbar-width: none;
        -ms-overflow-style: none;
        gap: 10px;
    }

    .cardContainer::-webkit-scrollbar {
        display:none;
    }

    .card{
        padding: 1%;
        /* margin-right: 5%; */
        min-width: 12vw;
        max-width: 12vw;
        height:auto;
    }

    .infoDiv {
        height:20%;
        overflow: hidden;
    }

    .info {
        overflow: hidden;
    }

    .modalImageContainer {
        width:20%;
        margin-right: 5%;
    }

    .modalBody{
        display:flex;
        flex-direction: row;
        align-items: center;
    }

    .profilePictureContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 5%;
        margin-top: 5%;
    }
    
    .profilePicture {
        object-fit: cover;
        width: 30px;
        height:30px;
        border-radius: 50%;
        background-color: #FFF;
    }
    
    .clientProfilePicture {
        object-fit: cover;
        width: 60%;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
    }

    .search {
        width: 15%;
    }

    .sub2, .sub3 {
        margin-top: 1%;
        padding-top: 2%;
        padding-bottom: 2%;
        display: flex;
        justify-content: space-between;
        width:49%;
        padding-right: 2%;
        box-shadow: none;
    }

    .checkInDiv, .subChild2 {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        box-shadow: 0px 0px 15px rgba(0,0,0,0.4);
        border-radius: 20px;
        /* padding: 10px 20px; */
    }

    .nutritionContainer{
        width: 100%;
        margin-top: 5%;
        display: flex;
        justify-content: center;
    }
    .lureSub{
        width: 30%;
    }

    .checkInCardsContainer,  .workoutCardsContainer{
        flex-direction: column;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        padding-bottom: 20px;
    }
}