.cardContainer {
    width:100%;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    scrollbar-width: none; /* Fjerner scrollbar i Firefox */
    -ms-overflow-style: none;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
}

.cardContainer::-webkit-scrollbar {
    display: none;
}

.card {
    min-width: 67vw;
    max-width: 67vw;
    height: 20vh;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.5);
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.infoDiv {
    border-radius: 20px;
    background-color: rgba(255,255,255,0.7);
    padding: 5px 15px;
    font-weight: 700;
}

.modalInfo {
    margin-top: 5%;
}

.modal {
    margin-top: 15%;
    padding-bottom: 10%;
}

.closeButton {
    padding:5px 15px;
    background-color: lightcoral;
    border-radius: 20px;
    text-align: center;
}

.closeButton:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media (min-width: 1000px) {
    .card {
        max-width:250px;
        min-width: 250px;
        border: 2px solid transparent;
        transition: 0.3s ease border-color;
    }

    .card:hover {
        cursor: pointer;
        border-color: #FFB34B;
    }

    .modal {
        margin-top: 0;
    }
}