.detailContainer {
    /* position: fixed; */
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transition: left 0.5s ease;
    color:#000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5%;
}

.backIcon {
    fill:#000;
}

/* .showDetail {
    left: 0;
} */

.mealImage {
    width: 100%;
    height: 40vh;
    background-position: center;
    background-size: cover;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}
.backIcon {
    height: 26px;
    padding-bottom: 1%;
}
.backButton {
    font-size: 16px;
    /* background: lightgray; */
    /* padding: 8px 16px; */
    border: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: none;
    background: none;
    color:#000;
}
/* .backButton {
    font-size: 16px;
    background: lightgray;
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 3%;
} */

.name {
    font-size: 1.8em;
    font-weight: 600;
}

.category {
    margin-bottom: 5%;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 0.7em;
}

.prepInfo {
    width: 95%;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.35);
    border-radius: 20px;
    padding: 25px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -5%;
    background-color: #FFF;
}

.prepChild {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.prepSubHeader {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 5px;
}

.iconContainer {
    width: 17%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-bottom: 5%;
}

.prepText {
    padding:0;
    margin:0;
}

.nutritionContainer, .ingredientsContainer, .tabsContainer {
    margin-top: 5%;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.35);
    width: 95%;
}

.tabsContainer {
    margin-bottom: 50px;
}

.nutriChild {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 3%;
}

.nutriSub1{
    display:flex;
    flex-direction: column;
    width: 23%;
    align-items: center;
    justify-content: space-between;
}

.nutriSubHeader {
    margin-bottom: 5%;
    margin-top: -2%;
    font-weight: 300;
    font-style: italic;
}

.nutriP {
    font-size: 1.1em;
    font-weight: 600;
}

.nutriS {
    font-size: 0.85em;
}

.ingredientChild {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2%;
    border-bottom: 1px solid lightgray;
    padding-bottom: 2%;
    padding-top: 3%;
}

.ingredientIconContainer {
    width: 6%;
}

.customTabs {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
    width: 100%;
}

.tabButton {
    flex: 1;
    padding: 10px 0;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: #555;
    transition: color 0.3s ease;
    outline: none;
}

.tabButton:hover {
    color: #FFB34B;
}

.activeTab {
    color: #FFB34B;
    border-bottom: 3px solid #FFB34B;
}

.tabContent {
    padding: 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 0 0 20px 20px;
    border-top: none;
    margin-top: -1px;
    background-color: #fff;
}