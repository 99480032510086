@import '../../../../styles/variables.scss';

.programItem {
    display: flex;
    flex-direction: column;
    min-width: 40vw;
    max-width: 40vw;
    height: 20vh;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background-color: #E4E4E4;
    margin: auto;
    font-style: italic;
    font-weight: 600;
}

.coachDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.p, .workoutP{
    margin-bottom: 0;
    font-size: 1.1em;
    font-weight: 600;
    font-style: italic;
    color:#000;
    padding:1% 2%;
    border-radius: 10px;
    background-color: rgba(228, 228, 228, 0.6);
}

.programHeader{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width:100%;
    height:70%;
    border-radius: 10px 10px 0 0;
}

.programsDivInner, .workoutsDivInner {
    display: flex;
    overflow-x: auto;
    gap: 3%;
    padding-left: 2%;
    padding-right: 2%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.container {
    background-color: $info;
}
.programsDivInner::-webkit-scrollbar, .checkInCardsContainer::-webkit-scrollbar, .workoutsDivInner::-webkit-scrollbar {
    display: none;
}

.programsDiv:hover {
    cursor:pointer;
}

.workoutsDivInner{
    height:25vh;
}

.workoutItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background-color: #E4E4E4;
    min-width:55vw;
    max-width: 55vw;
    min-height:100%;
    max-height: 100%;
    padding:2%;
    margin:auto;
    background-position: center;
    background-size: cover;
    background-image: url(../../../../images/db_rack_2.jpg);
    background-color: rgba(255, 255, 255, 0.3);
    background-blend-mode: overlay;
}

.programsDiv, .workoutsDiv, .nutritionContainer {
    margin-bottom: 7%;
}

.programsDiv{
    background-color: darkgray;
    padding-top: 3%;
    padding-bottom: 3%;
}
/* .programsDiv h2{
    font-weight: 600;
} */
.userInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.userInfoImageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    overflow:hidden;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
}
.userInfoImage {
    object-fit: cover;
    margin: 0 auto;
    height: 100%;
    width:auto;
    display: inline;
}

.userName{
    font-size: 1.3em;
    font-weight: 700;
}

.checkInDiv{
    width:90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    //background-color: #E4E4E4;
    padding-bottom: 5%;
    margin-bottom: 7%;
    margin-top: 7%;
    //background-image: url(../../../../images/weigh-in.jpg);
    //background-size: cover;
    //background-color: rgba(255, 255, 255, 0.25);
    //background-blend-mode: overlay;
    //background-position: center 60%;
}

.checkInHeader {
    display:flex;
    width:100%;
    align-items: center;
    justify-content: space-between;
    padding-left: 1%;
    padding-right: 1%;
    //background-color: darkgray;
}

.checkInOverskrift {
    font-size: 1.7em;
    width: auto;
    color: #000;
    //margin-left: 2%;
}
.checkInKnapp{
    width:35%;
    background-color: #FFB34B;
    text-align: center;
    border-radius: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    //margin-right: 2%;
}

.checkInCardsContainer {
    width: 100%;
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.weights {
    text-align: center;
    font-weight:600;
    font-style: italic;
    margin-top: 4%;
}

.total {
    font-size: 1.3em;
    font-weight: 700;
}



.coachCard {
    background-color: #FFF;
    margin-bottom: 4%;
    padding:3%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.coachOverskrift {
    font-size: 1.7em;
    //padding-left: 3%;
}

.coachImageDiv {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height:10vh;
    width:30%;
    border-radius: 50%;
}

.coachInfo {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.coachInfo p{
    font-size: 0.7em;
}

.chartContainer {
    background-color: #FFF;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    padding-top: 15px;
    padding-bottom: 15px;
}

.chartHeader {
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
}

.periodToggle {
    display: none;
}

.form {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.uploadProfileImageConntainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subDiv {
    display: flex;
    justify-content: center;
}

.formButton, .imageButtons {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFB34B;
    text-align: center;
    border-radius: 20px;
    margin-top: 5%;
    margin-bottom: 10%;
}

.iconContainer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #FFB34B;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.diskIcon{
    color:#000;
    width: 30px;
    height: 30px;
}

.disabled {
    color:gray;
}

.buttonContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.imageButtons {
    width: auto;
    padding: 1px 15px;
}
.deleteContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.deleteButton {
    padding: 5px 25px;
    background-color: lightcoral;
    border-radius: 20px;
}