.rating {
    display: flex;
    font-size: 2rem;
    align-items: center;
}

.starFilled {
    color: gold;
    margin-right: 5px;
}

.starEmpty {
    color: lightgray;
    margin-right: 5px;
}

.container {
    font-size: 1.2rem;
}

.images {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.largeImage {
    image-orientation: from-image;
}