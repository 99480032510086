.container {
    width: 100%;
    /* box-shadow: 0px 0px 20px rgba(0,0,0,0.3); */
}

.search {
    border-radius: 20px;
    border: 1px solid gray;
    padding-left: 2%;
    padding-top: 1%;
    margin-bottom: 2%;
}

.subHeader {
    font-size: 1.8em;
    font-weight: 600;
    padding-left: 1%;
}


.subChild {
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.addButton {
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    background-color: #FFB34B;
    text-align: center;
    padding-bottom: 3px;
}

.addButton:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media (min-width: 1000px) {
    .container{
        justify-content: center;
    }
    .subHeader {
        text-align: center;
    }

}