@import '../../styles/variables.scss';
.container {
    //margin-bottom: 15vh;
    margin-bottom: 11%;
}
.main, .conatiner {
    background-color: $info;
}

.outletDiv {
    margin-top: 13vh;
    overflow-y: auto;
    background-color: $info;
}

.outletDiv::-webkit-scrollbar {
    display: none;
}

.outletDiv {
    scrollbar-width: none; /* Skjuler scrollbar i Firefox */
}
@media (min-width: 720px) {
    .container {
        margin-bottom: 0;
        margin-left:12%;
    }
    .outletDiv {
        margin-top: 0;
    }
}

@media (min-width: 1000px) {
    .container {
        margin-left: 6%;
        background: linear-gradient(to bottom,#fff, #f2f2f2) ;
        background-attachment: fixed;
        min-height: 100vh;
    }

    /* .outletDiv {
        padding-bottom: 10%;
    } */
}