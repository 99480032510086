@import '../../../../styles/variables.scss';

.h1 {
    text-align: center;
    font-size: 2em;
    font-weight: 800;
}

.container1 {
    padding-top: 5%;
    padding-bottom: 7%;
    background-color: $info;
}