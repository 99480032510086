@import '../../../../styles/variables.scss';

.rating {
    display: flex;
    font-size: 2rem;
    align-items: center;
}

.starFilled {
    color: gold;
    margin-right: 5px;
}

.starEmpty {
    color: lightgray;
    margin-right: 5px;
}

.modal {
    margin-top: 10%;
    padding-bottom: 10%;
    margin-bottom: 10%;
}

.clientComment {
    margin-top: 5%;
    margin-bottom: 5%;
}

.container{
    width: 100%;
    display: flex;
    overflow-x: auto;
    gap:5%;
    padding: 2% 2%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-bottom: 5%;
    margin-bottom: 7%;
    justify-content: start;
    /* background-image: url(../../../../../images/weigh-in.jpg);
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.25);
    background-blend-mode: overlay; */
}

.container::-webkit-scrollbar {
    display: none;
}

.card {
    min-width: 70vw;
    max-width: 70vw;
    padding: 2% 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
    /* background-image: url(../../../../../images/weigh-in.jpg); */
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.2);
    background-blend-mode: overlay;
    background-position: center 65%;
    color: #000;
}

.cardInfo {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 20px;
    color:#fff;
    margin-top: 3%;
}

.workoutCard {
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 2px solid transparent;
    transition: 0.3s ease border-color;
}

.clientWorkoutImage {
    width: 50%;
    height: auto;
}

.clientProfilePicture {
    border-radius: 50%;
    aspect-ratio: 1 / 1 ;
    object-fit: cover;
}

.workoutCard:hover{
    border-color: #FFB34B;
    cursor: pointer;
}

.modalBody {
    display: flex;
    flex-direction: column;
    width:100%;
    align-items: center;
}

.coachCommentTextarea{
    width: 80%;
    min-height: 70px;
    border: 1px solid black;
    border-radius: 10px;
    padding:1%;
}

.saveButton, .closeButton {
    border-radius: 10px;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
}

.saveButton:hover, .closeButton:hover {
    cursor: pointer;
}

.saveButton {
    background-color: #FFB34B;
}

.saveButton:hover {
    background-color: #000;
    color: #FFB34B;
}

.closeButton {
    background-color: lightcoral;
}

.closeButton:hover {
    background-color: #000;
    color: lightcoral;
}

.headerContainer {
    height: 300px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding-bottom: 10%;
    margin-bottom: 10%;
    border-radius: 20px;
}

.exerciseCard {
    display:flex;
    flex-direction: column;
    background-color: $info;
    margin-bottom: 3%;
    border-radius: 10px;
    padding-bottom: 2%;
    width:100%;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.set {
    display: flex;
    width:100%;
    justify-content: space-around;
    align-items: center;
    height:100%;
    /* margin-left: 1%; */
    /* gap: 2%; */
    padding-left: 1%;
    padding-right: 1%;
}

.subHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 1%;
    padding-right: 1%;
    //background-color: #FFB34B;
    margin-bottom: 1%;
    background-color: lightgray;
    //border-bottom: 1px solid lightgray;
}

.subReps {
    width:25%;
}

.input{
    width:25%;
    border:0;
    /* border-bottom: 1px solid darkgray; */
    border-bottom: 1px solid darkgray;
}
.presetReps{
    width:25%;
    text-align: center;
}
.setInput {
    margin-left: 3%;
    border-right: 1px solid darkgray;
}

.cardHeader{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    /* margin-bottom: 1%; */
    //background-color: $info;
    border-radius: 10px 10px 0 0;
    height:20%;
}

.cardHeaderImageContainer{
    height:5em;
    width:30%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px 0 0 0;
}

.exerciseNameDiv {
    height:5em;
    width:65%;
    display:flex;
    align-items: center;
    justify-content: flex-start;
}

.exerciseName {
    font-style: italic;
    margin-bottom: 0;
}

.timerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
}
.timer {
    width: 50%;
    background-color: #E4E4E4;
    text-align: center;
    border-radius: 20px;
}

.headerNavn {
    text-align: center;
    font-size: 2em;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    margin-bottom: 20%;
    padding: 5px 10px;
    color:#FFF;
}

.inputRating, .totalWeight {
    font-size: 1.7em;
}

.inputRating {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.saveButton, .closeButton {
    border-radius: 10px;
    padding-left: 5%;
    padding-right: 5%;
    text-align: center;
}

.saveButton:hover, .closeButton:hover {
    cursor: pointer;
}

.saveButton {
    background-color: #FFB34B;
}

.saveButton:hover {
    background-color: #000;
    color: #FFB34B;
}

.cardWorkoutName {
    font-size: 1.6em;
    text-align: center;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    color:#FFF;
    font-weight: 600;
}

.searchBar {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3%;
    justify-content: center;
    width: 50%;
}

.searchInput {
    padding: 0px 15px;
    border-radius: 20px;
    border: none;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
    line-height: 2;
    vertical-align: bottom;
    box-sizing: border-box;
}

.parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (min-width: 1000px) {

    .searchBar {
        width:auto;
    }
    .coachCommentTextarea{
        width: 50%;
    }

    .card{
        min-width: 200px;
        max-width: 550px;
        width: 550px;
        overflow-x: hidden;
        border: 2px solid transparent;
        transition: 0.3s ease border-color, transform 0.3s ease ;
        flex-direction: row;
        margin-bottom: 2%;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        box-sizing: border-box;
    }

    .cardWorkoutName, .cardInfo {
        font-size: 1em;
        margin:0;
        background: none;
        color:#000;
        width:30%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .hideBackgroundDesktop {
        background-image: none;
    }

    .card:hover{
        cursor: pointer;
        border-color: #FFB34B;
        transform: scale(1.05);
    }

    .container {
        min-height: 300px;
        flex-direction: column;
    }
}