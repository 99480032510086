.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap:10px;
}

.input, .select {
    border-radius: 20px;
    border: 1px solid gray;
    padding-left: 15px;
    padding-top: 5px;
    width: 100%;
}

.submitButton {
    padding: 5px 15px;
    border-radius: 20px;
    background-color: #FFB34B;
    margin-top: 5%;
}

@media (min-width: 1000px) {
    .input, .select {
        width: 50%;
    }
}