@import '../../../styles/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 98%;
    background-color: $info;
    border-radius: 10px;
    height: 70vh;
    margin: auto;

    @media(min-width:768px) {
        width: 100%;
    }
}

.window {
    display: flex;
    flex-direction: column;
    width: 95%;
    background-color: #FFF;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3%;
    margin-bottom: 3%;
    padding: 2%;
    border-radius: 10px;
    height:100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.window::-webkit-scrollbar {
    display: none; /* Fjerner scrollbar i Chrome, Safari og Opera */
}

.message {
    max-width: 75%;
    padding: 3%;
    border-radius: 10px 10px 10px 0px;
    margin: 1% 0;

    @media(min-width: 768px) {
        padding: 1%;
    }
}

.userMessage {
    background-color: #FFB34B;
    color: #000;
    align-self: flex-end; /* Plasser meldingen til høyre */
    border-radius: 10px 10px 0px 10px; /* Juster border-radius for brukerens meldinger */
}

.otherMessage {
    background-color: #444;
    color: #FFF;
    align-self: flex-start; /* Plasser meldingen til venstre */
    border-radius: 10px 10px 10px 0px; /* Juster border-radius for andre meldinger */
}

.overskrift {
    font-size: 2em;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    margin-top: 2%;
}

.conversationsList {
    width: 95%;
    background-color: #f5f5f5;
    padding: 1%;
    margin:auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    overflow-x: auto;
    white-space: nowrap;
    height:10vh;
    border-radius: 10px;
}

.conversationItem {
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 50vw;
    text-align: center;
    display: flex;
    align-items: baseline;
}

.conversationItem:hover {
    background-color: #FFB34B;
}

.messagesWindow {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 85%;
    overflow-y: auto;
}

.selectedConversation {
    background-color: #FFB34B;
    color: #000;
}

.messageInputContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 3%;
    padding-bottom: 2%;
}

.messageInput {
    width: 80%;
    border-radius: 10px;
    border: 1px solid darkgray;
    padding: 1% 3%;
    height:100%;
}
.sendButton {
    padding: 1% 3%;
    border-radius: 10px;
    background-color: #FFB34B;
    font-size: 1em;
    border: 1px solid transparent;
    font-style: italic;
    color: #000;
}

.innerWindow {
    display: flex;
    flex-direction: column;
    height:100%;
    justify-content: space-between;
}

.clientWindow {
    display: flex;
    flex-direction: column;
    width: 95%;
    background-color: #FFF;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3%;
    margin-bottom: 3%;
    padding: 2%;
    border-radius: 10px;
    height:100%;
    overflow-y: scroll;
}

.clientSelect, .nySamtale {
    color:#000;
    border-radius: 20px;
    padding: 1% 3%;
}

.nySamtale {
    border: 2px solid transparent;
    font-style: italic;
}

.nySamtale:hover {
    text-decoration: underline;
    cursor: pointer;
}

.startConversationContainer {
    width: 80%;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
}

/* @media (min-width:1000px) {
    .container {
        width: 100%;
        box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
        height: auto;
    }

    .conversationsList {
        height:6vh;
    }

    .startConversationContainer {
        margin-top: 1%;
    }

    .innerWindow{
        height: 50vh;
    }

    .overskrift {
        font-weight: 900;
    }
    
} */

@media (min-width: 720px) {
    .container {
      flex-direction: row; /* Endre retningen til rad */
      height: 85vh; /* Juster høyden etter behov */
      justify-content: space-between;
      box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
    }
  
    .conversationsList {
      flex-direction: column;
      width: 100%; /* Sett bredde for samtalelisten */
      height: auto;
      overflow-y: auto;
      align-items: flex-start;
      padding: 1%;
    }
  
    .conversationItem {
      width: 100%;
      margin-bottom: 10px;
      text-align: left;
    }
  
    .window {
      width: 75%; /* Sett bredde for meldingsvinduet */
      margin: 0; /* Fjern margin */
      padding: 2%;
      height: auto;
      overflow: hidden;
    }

    .sub1{
        width:25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
  
    .innerWindow {
      height: 100%;
    }
  
    .startConversationContainer {
      flex-direction: column;
      align-items: flex-start;
      width: 100%; /* Match bredden til samtalelisten */
      margin-top: 20px;
      justify-content: center;
      align-items: center;
      margin-bottom: 20%;
      gap:10px;
    }
  
    .messageInputContainer {
      padding-bottom: 0;
    }
  
    .messagesWindow {
      max-height: calc(80vh - 150px);
      font-size: 0.8rem;
    }
  
    .overskrift {
      width: 100%;
      text-align: center;
    }
  }