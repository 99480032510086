@import '../../../styles/variables.scss';

.lagNy {
    background-color: $primary;
    @media(min-width: 768px) {
        transition: transform 0.3s ease;
        &:hover {
            transform: scale(1.05);
            cursor: pointer;
        }
    }
}

.overskrift {
    font-size: 1.5rem;
}

.container {
    background-color: $info;
    min-height: 85vh;
}