@import '../../../styles/variables.scss';

.container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
    justify-content: space-evenly;
    padding-top: 5vh;
    padding-bottom: 6.5vh;
    background-color: $info;
}

.container2 {
    display:flex;
    flex-direction: column;
    height: 100%;
}

.cardContainer {
    width: 90%;
    min-height: 20vh;
    margin: auto;
    border-radius: 10px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-overflow: ellipsis;
    overflow:hidden;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    color:#000;
    text-decoration: none;
    border: 2px solid $info;
    transition: 0.3s ease border-color;
}

.cardContainer:hover {
    cursor: pointer;
    border-color: #FFB34B;
}

.imageContainer {
    width:100%;
    height:40vh;
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 3%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.cardContainer2 {
    margin-top: 3vh;
}

.text {
    padding: 0px 10px;
}

.h2{
    font-size: 2em;
    font-weight: 800;
    margin-bottom: 6%;
}

.container3 {
    display: flex;
    flex-direction: column;
}


@media (min-width: 720px) {
    .container2 {
        flex-direction: row;
    }

    .container1 {
        padding-bottom: 10%;
    }

    .cardContainer {
        width: 30%;
        height: 450px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
    .cardContainer:hover {
        transform: scale(1.05);
    }

    .cardContainer2 {
        margin-top: 0;
    }
}