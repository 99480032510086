.rating {
    display: flex;
    font-size: 2rem;
    align-items: center;
}

.starFilled {
    color: gold;
    margin-right: 5px;
}

.starEmpty {
    color: lightgray;
    margin-right: 5px;
}

.images {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    width:100%;
}

.image {
    width: 30%;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}

.modalImageParent {
    width:100%;
}

.container{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap:15px;
    margin-top: 7%;
    //padding: 2% 2%;
    //padding-bottom: 2%;
    /* background-image: url(../../../../../images/weigh-in.jpg);
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.25);
    background-blend-mode: overlay; */
}

.container::-webkit-scrollbar {
    display: none;
}

.card {
    width: 100%;
    height: auto;
    padding: 2% 3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    /* background-image: url(../../../../../images/weigh-in.jpg); */
    background-size: cover;
    background-color: #FFF;
    background-blend-mode: overlay;
    color: rgba(0,0,0,0.75);
    backdrop-filter: blur(10px); /* Legger til blur-effekt */
    -webkit-backdrop-filter: blur(10px);
}

.weightUp, .weightDown {
    font-size: 1em;
    font-style: italic;
    font-weight: 700;
    //padding: 5px 20px;
    //background-color: rgba(0, 0, 0, 0.35);
    border-radius: 20px;
}

.weightUp {
    //background-color: rgba(240, 128, 128, 0.55);
    color: #bf0404;
}

.weightDown {
    //background-color: rgba(144, 238, 144, 0.55);
    color:#04bf17;
}

.deleteButton {
    background-color: lightcoral;
    border-radius: 10px;
    //padding: 3px 20px;
    //width: 260px;
    text-align: center;
    margin-bottom: 10%;
}

.checkInDate {
    font-size:1em;
    //font-weight: 800;
    text-align: center;
    //font-style: italic;
    //text-decoration: underline;
}

.content{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height:100%;
    align-items: center;
}
.content p{
    margin:0;
    text-align: center;
    font-weight: 800;
}

.coach {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

// .bold {
//     font-weight: 800;
//     font-size: 1em;
//     font-style: italic;
// }

.bold2 {
    font-size: 1.2em;
}

.modal {
    margin-top: 10%;
    padding-bottom: 10%;
    margin-bottom: 10%;
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.5em;
    gap:10px;
}