@import '../../../styles/variables.scss';

.parent {
    width: 100%;
    margin:auto;
    background-color: $info;
}

.subChild1 {
    font-weight: 600;
    font-style: italic;
    width:100%;
}

.subChild2 {
    font-size: 1.6em;
    font-weight: 600;
}

.child1 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.child2, .child3, .stats{
    margin-top: 5%;
}

.child2, .stats, .incompleteContainer {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background-color: #FFF;
}

.child3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.paddingTop {
    padding-top: 3%;
}

.incompleteContainer {
    margin-top: 3vh;
}

.resumeBanner {
    display: flex;
    width:100%;
    align-items: center;
    justify-content: center;
  }

  .resumeButton, .discardButton {
    border-radius: 10px;
    text-align: center;
  }

  .resumeButton{
    background-color: lightgreen;
  }

  .discardButton {
    background-color: lightcoral;
  }