@import '../../../../styles/variables.scss';

.card {
    background-color: #FFF;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: #000;
    font-weight: 600;
    border:3px solid transparent;
    transition: 0.3s ease border-color, transform 0.3s ease;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    @media(min-width:768px){
        &:hover{
            cursor: pointer;
            border-color: $primary;
            transform: scale(1.02);
        }
    }
}