.container1 {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
}

.input {
    width: 70vw;
}

.option {
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 25vh;
    justify-content: space-between;
    overflow: hidden;
}

.image {
    height: 70%;
}
.text {
    font-size: 1.2em;
    text-align: center;
}

.container2 {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0,0,0, 0.1);
    border-radius: 15px;
    padding: 20px;
    background-color: #FFF;
}

.smallText {
    font-size: 0.7em;
    text-align: center;
    width: 50%;
    margin-top: 3%;
}

.button {
    border-radius: 5px;
    padding: 2px 10px;
    border: 2px solid transparent;
    box-shadow: 0px 2px 5px rgba(0,0,0, 0.1);
    background-color: #FFB34B;
    margin-top: 2vh;
    color: #000;
}

.button:hover {
    background-color: #000 ;
    color: #FFB34B;
}

.createButton {
    margin-top: 2vh;
}

.writtenInput, .writtenInputHeader {
    width: 70vw;
    border-radius: 20px;
    border: 2px solid transparent;
    margin-top: 1vh;
    height: 4vh;
    padding-left: 2vw;
    box-shadow: 2px 5px 15px rgba(0,0,0, 0.1);
}
.setsContainer {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.setIndex {
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1vh;
}

.setInput {
    border-radius: 20px;
    border: 2px solid transparent;
    margin-top: 1vh;
    height: 4vh;
    padding-left: 2vw;
    box-shadow: 2px 5px 15px rgba(0,0,0, 0.1);
    width: 35%;
}

.setRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.userText {
    margin-top: 5%;
    font-size: 1.3em;
}

.underoverskrift {
    font-size: 1.3em;
    text-decoration: underline;
    font-style: italic;
}

.removeButton {
    border-radius: 5px;
    padding: 2px 10px;
    border: 2px solid transparent;
    box-shadow: 0px 2px 5px rgba(0,0,0, 0.1);
    background-color: #FFB34B;
    margin-top: 1vh;
    color: #000;
}

.removeButton:hover {
    background-color: #000 ;
    color: #FFB34B;
    cursor: pointer;
}

.addButtonDiv {
    width: 100%;
    display: flex;
    justify-content: center;
}

.commentInput {
    border-radius: 20px;
    width: 100%;
    padding: 10px 20px;
    margin-top: 10%;
    box-shadow: 0px 0px 10px rgba(0,0,0, 0.1);
    border: none;
}

.commentContainer {
    width: 100%;
}

@media (min-width: 720px) {

    .writtenInput {
        width: 70%;
        padding-left: 2%;
    }

    .writtenInputHeader {
        width: 30%;
        padding-left: 2%;
        box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
    }

    .cardContainer {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        justify-content: center;
        background-color: #FFF;
        @media (min-width: 768px) {
            background-color: transparent;
        }
    }

    .container2 {
        box-shadow: 2px 2px 15px rgba(0,0,0, 0.1);
        border-radius: 15px;
        padding: 20px;
        margin: 1%;
    }

    .container1 {
        margin-bottom: 0;
        padding-bottom: 5%;
    }

}