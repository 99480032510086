.test {
    height:70vh;
}

.buttonRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    gap: 20px;
}

.viewButton, .markReadButton {
    border:none;
    border-radius: 5px;
    text-align: center;
    padding: 10px 20px;
    color: #000;
    transition: transform 0.3s ease;

    @media (min-width: 768px) {
      &:hover {
        transform: scale(1.05);
        cursor: pointer;
      }
    }
}

.viewButton {
    background-color: lightgreen;
}

.markReadButton {
    background-color: lightgray;
}

.notificationItem {
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgray;
    margin-top: 10px;
}

.notificationList {
    border-top: 1px solid lightgray;
}

.notificationsWrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

/* fade-enter: starter med 0 opasitet og litt opp */
.fade-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  /* fade-exit: starter med full opasitet */
  .fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .fade-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 300ms, transform 300ms;
  }
  